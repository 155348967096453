import React, { useContext, useEffect, useState } from "react";
import Eye from "../assets/icons/eye.svg";
import Back from "../assets/icons/back.svg";
import Pencil from "../assets/icons/pencil.svg";
import { ReactSVG } from "react-svg";
import { AppContext } from "../context/AppContext";
import moment from "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import SalesForm from "../components/sales/SalesForm";
import Modal from "../components/common/Modal";
import { DataTable } from "../components/ui/data-table";
import { getAllSales, getSalesDetails } from "../api/sales";
import { Label } from "../components/ui/label";
import { Input } from "../components/ui/input";
import { getAllDetails } from "../api/saleDetails";
import { Button } from "../components/ui/button";
import { ArrowUpDown } from "lucide-react";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import Category from "../components/category/Category";
import Folio from "../components/folios/Folio";

function Sales() {
  const { domain, token } = useContext(AppContext);
  const [sales, setSales] = useState([]);
  const [details, setDetails] = useState([]);
  const [loader, setLoader] = useState(false);

  const documents = [
    { id: 1, name: "Detalle de venta (Manual)" },
    { id: 2, name: "Boleta Electrónica" },
    { id: 3, name: "Factura Electrónica" },
    { id: 4, name: "Cotizaciones" },
  ];

  useEffect(() => {
    async function fetchData() {
      try {
        setLoader(true);
        const data = await getAllSales(domain, token);
        setSales(data);
        const detailsData = await getAllDetails(domain, token);
        setDetails(detailsData);
        setLoader(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  function getDetailsBySaleId(saleId) {
    return details.filter((detail) => detail.sales.id === saleId);
  }

  async function exportToExcel() {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const data = [];
    for (let index = 0; index < sales.length; index++) {
      const sale = sales[index];
      const payment = JSON.parse(sale.payment_method);
      const documentName = documents[sale.document - 1]?.name || "Unknown";
      data.push({
        Folio: sale.folio,
        Neto: sale.net_price,
        IVA: sale.tax,
        Bruto: sale.gross_price,
        Metodo_de_pago: payment[0].method,
        Documento: documentName,
        Vendedor: sale.seller,
      });
    }
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excelBlob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(excelBlob, "data" + fileExtension);
  }

  const columns = [
    {
      accessorKey: "folio",
      header: "Folio",
      filterFn: (row, columnId, filterValue) => {
        const element = row.original;
        return element.folio.toString().includes(filterValue);
      },
    },
    {
      accessorKey: "date",
      header: "Fecha",
      cell: ({ row }) => {
        const sale = row.original;
        return (
          <>
            {moment
              .tz(sale.date, "America/Santiago")
              .format("YYYY-MM-DD HH:mm:ss")}
          </>
        );
      },
      meta: {
        filterVariant: "date",
      },
      filterFn: (row, columnId, filterValue) => {
        const timeZone = "America/Santiago";
        if (filterValue.to !== undefined) {
          const dateFrom = moment.tz(filterValue.from, timeZone);
          const dateTo = moment.tz(filterValue.to, timeZone);
          const filterDate = moment.tz(row.original.date, timeZone);

          return filterDate.isBetween(dateFrom, dateTo);
        } else {
          if (filterValue.from) {
            const filterDate = moment.tz(row.original.date, timeZone);
            const dateFrom = moment.tz(filterValue.from, timeZone);

            return (
              filterDate.format("YY-MM-DD") === dateFrom.format("YY-MM-DD")
            );
          } else {
            return true;
          }
        }
      },
    },
    {
      accessorKey: "document",
      header: "Documento",
      cell: ({ row }) => {
        const sale = row.original;
        const documentName = documents[sale.document - 1]?.name || "Unknown";
        return <>{documentName}</>;
      },
      filterFn: (row, columnId, filterValue) => {
        const documentName = documents[row.original.document - 1]?.name || "";
        return documentName
          .toLocaleLowerCase()
          .includes(filterValue.toLocaleLowerCase());
      },
    },
    {
      accessorKey: "payment_method",
      header: "Métodos de pago",
      cell: ({ row }) => {
        const sale = row.original;
        console.log(sale);
        const payment_method = JSON.parse(sale.payment_method);
        return (
          <>
            {payment_method.map((payment) => (
              <p key={payment.method}>{payment.method}</p>
            ))}
          </>
        );
      },
    },
    {
      accessorKey: "action",
      header: "Acciones",
      cell: ({ row }) => {
        const sale = row.original;
        const eachDetail = getDetailsBySaleId(sale.id);
        return (
          <>
            <Modal
              large={true}
              name={<ReactSVG src={Eye} className="text-dark" />}
              title={"Folio:" + sale.folio}
              className={"px-2 button-no-style"}
              content={
                <div>
                  <Label>
                    Folio
                    <Input disabled value={sale.folio} />
                  </Label>
                  <Label>
                    Fecha
                    <Input disabled value={sale.date} />
                  </Label>
                  <Label>
                    Precio Neto
                    <Input disabled value={sale.net_price} />
                  </Label>
                  <Label>
                    Impuesto
                    <Input disabled value={sale.tax} />
                  </Label>
                  <Label>
                    Precio Bruto
                    <Input disabled value={sale.gross_price} />
                  </Label>
                  <Label>
                    Método de pago
                    <Input
                      disabled
                      value={JSON.parse(sale.payment_method)[0].method}
                    />
                  </Label>
                  <Label>
                    Vendedor
                    <Input disabled value={sale.seller} />
                  </Label>
                  <div>
                    <p>Detalle de venta</p>
                    {console.log(eachDetail)}
                    {eachDetail.map((detail) => (
                      <div key={detail.id}>
                        <Label>
                          Nombre
                          <Input disabled value={detail.name} />
                        </Label>

                        <Label>
                          Cantidad
                          <Input disabled value={detail.qty} />
                        </Label>

                        <Label>
                          Precio Unitario
                          <Input disabled value={detail.price_unit} />
                        </Label>

                        <Label>
                          Precio Total
                          <Input disabled value={detail.price_total} />
                        </Label>
                      </div>
                    ))}
                  </div>
                </div>
              }
            />
            <Modal
              large={true}
              name={<ReactSVG src={Back} className="text-dark" />}
              title={"Folio:" + sale.folio}
              className={"px-2 button-no-style"}
              content={
                <div>
                  <Label>
                    Folio
                    <Input disabled value={sale.folio} />
                  </Label>
                  <Label>
                    Fecha
                    <Input disabled value={sale.date} />
                  </Label>
                  <Label>
                    Precio Neto
                    <Input disabled value={sale.net_price} />
                  </Label>
                  <Label>
                    Impuesto
                    <Input disabled value={sale.tax} />
                  </Label>
                  <Label>
                    Precio Bruto
                    <Input disabled value={sale.gross_price} />
                  </Label>
                  <Label>
                    Método de pago
                    <Input
                      disabled
                      value={JSON.parse(sale.payment_method)[0].method}
                    />
                  </Label>
                  <Label>
                    Vendedor
                    <Input disabled value={sale.seller} />
                  </Label>
                  <div>
                    <p>Detalle de venta</p>
                    {console.log(eachDetail)}
                    {eachDetail.map((detail) => (
                      <div key={detail.id}>
                        <Label>
                          Nombre
                          <Input disabled value={detail.name} />
                        </Label>

                        <Label>
                          Cantidad
                          <Input disabled value={detail.qty} />
                        </Label>

                        <Label>
                          Precio Unitario
                          <Input disabled value={detail.price_unit} />
                        </Label>

                        <Label>
                          Precio Total
                          <Input disabled value={detail.price_total} />
                        </Label>
                      </div>
                    ))}
                  </div>
                </div>
              }
            />
          </>
        );
      },
    },
  ];
  const extra = (
    <>
      <Modal
        large={true}
        name="Añadir Folio"
        title="Añadir Folio"
        className={"w-100"}
        content={<Folio />}
      />
    </>
  );

  return (
    <>
      {loader ? (
        <div className="flex justify-center w-full align-center items-center">
          <img
            class="w-20 h-20 animate-spin"
            src="https://www.svgrepo.com/show/70469/loading.svg"
            alt="Loading icon"
          ></img>
        </div>
      ) : (
        <div>
          <h2>Ventas</h2>
          <DataTable
            columns={columns}
            data={sales}
            exportToExcel={exportToExcel}
            Extra={extra}
          />
        </div>
      )}
    </>
  );
}

export default Sales;
