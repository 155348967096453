"use client";

import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  BarChart,
  Bar,
  ResponsiveContainer,
} from "recharts";
import { useContext, useEffect, useState } from "react";
import moment from "moment-timezone";
import { getAllSales } from "../api/sales";
import { getAllDetails } from "../api/saleDetails";
import { AppContext } from "../context/AppContext";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { Button } from "../components/ui/button";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "../components/ui/select";

export default function Dashboard() {
  const [loader, setLoader] = useState(false);
  const [dataSales, setDataSales] = useState([]);
  const [filterDataSales, setFilterDataSales] = useState([]);
  const [dataDetails, setDataDetails] = useState([]);
  const [timeFilter, setTimeFilter] = useState("7d");
  const [productFilter, setProductFilter] = useState("top");
  const [totalSalesToday, setTotalSalesToday] = useState(0);
  const [image,setImage] = useState("");


  const { domain, token } = useContext(AppContext);



  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      setLoader(true);
      const sales = await getAllSales(domain, token);
      setDataSales(sales)

      /**
            try {

              const dataProcess = processSalesData(sales);
              const urlApi = 'https://salesheatmap.azurewebsites.net/api/heatMapFunction';

              const response = await fetch(urlApi, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                  data: dataProcess
                })
              })
              const data = await response.text()
              setImage(data)
            } catch (error) {
              console.error(error);
            }
      */

      const details = await getAllDetails(domain, token);
      const top = getTop10Products(details)
      setDataDetails(top)
      setLoader(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }


  useEffect(() => {
    // Filtrar ventas según el filtro de tiempo
    const filteredSales = filterSalesByTime(dataSales);
    const salesFormat = formatSales(filteredSales)
    setFilterDataSales(salesFormat)

  }, [timeFilter, loader])

  const processSalesData = (sales) => {
    // Definir los rangos de horas y los días de la semana
    const hourRanges = [
      "07:00-08:00", "08:00-09:00", "09:00-10:00", "10:00-11:00",
      "11:00-12:00", "12:00-13:00", "13:00-14:00", "14:00-15:00",
      "15:00-16:00", "16:00-17:00", "17:00-18:00", "18:00-19:00",
      "19:00-20:00", "20:00-21:00", "21:00-22:00", "22:00-23:00",
      "23:00-00:00"
    ];

    const daysOfWeek = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];

    // Obtener la fecha actual para filtrar los días de la semana actual
    const startOfWeek = moment().startOf('week');
    const endOfWeek = moment().endOf('week');

    // Inicializar un objeto para acumular las cantidades por rango de hora y día
    let result = {};

    // Procesar cada venta en el array
    sales.forEach(sale => {
      const saleDate = moment(sale.date);

      // Filtrar solo las ventas de esta semana
      if (saleDate.isBetween(startOfWeek, endOfWeek, null, '[]')) {
        const dayOfWeek = daysOfWeek[saleDate.day()];
        const hour = saleDate.hour();

        // Encontrar el rango de horas correspondiente
        const hourRangeIndex = Math.floor(hour - 7);
        if (hourRangeIndex >= 0 && hourRangeIndex < hourRanges.length) {
          const hourRange = hourRanges[hourRangeIndex];

          const key = `${hourRange}-${dayOfWeek}`;

          // Acumular las cantidades en el objeto resultado
          if (!result[key]) {
            result[key] = { hourRange, days: dayOfWeek, quantities: 0 };
          }

          result[key].quantities += sale.gross_price;
        }
      }
    });

    // Convertir el objeto acumulado en un array
    return Object.values(result);
  };

  function getTop10Products(salesArray) {
    const productMap = {};

    salesArray.forEach(sale => {
        const { name, qty } = sale;
        if (productMap[name]) {
            productMap[name] += qty;
        } else {
            productMap[name] = qty;
        }
    });

    const productArray = Object.keys(productMap).map(name => ({
        name,
        qty: productMap[name]
    }));

    productArray.sort((a, b) => b.qty - a.qty);

    return productArray.slice(0, 10);
}

  function filterSalesByTime(dataSales) {
    return dataSales.filter((sale) => {
      const saleDate = moment(sale.date);
      if (timeFilter === "7d") {
        return moment(moment().toDate(), "MM-DD-YYYY").isoWeek() === moment(saleDate, "MM-DD-YYYY").isoWeek();
      } else if (timeFilter === "month") {
        return saleDate.isSame(moment(), "month");
      }
    });
  }

  function formatSales(sales) {
    const salesByDate = {};
    // Iterar sobre cada venta
    for (let index = 0; index < sales.length; index++) {
      const sale = sales[index];

      // Parsear la fecha y ajustar a la zona horaria de Chile
      const date = moment(sale.date).tz('America/Santiago').format('YYYY-MM-DD');

      // Si la fecha aún no existe en el objeto, inicializarla
      if (!salesByDate[date]) {
        salesByDate[date] = 0;
      }

      // Sumar el total de la venta para la fecha correspondiente
      salesByDate[date] += sale.gross_price;
    }

    // Convertir el objeto en un array con el formato deseado
    const result = Object.keys(salesByDate).map(date => ({
      date: date,
      total: salesByDate[date]
    }));

    return result;
  }

  return (
    <div className="space-y-4">
      {loader ?
        <>
          <div className='flex justify-center w-full align-center items-center'>
            <img class="w-20 h-20 animate-spin" src="https://www.svgrepo.com/show/70469/loading.svg" alt="Loading icon"></img>
          </div>
        </> :
        <>
          {/* Total Ventas de Hoy */}
        {
          /**
            <Card>
            <CardHeader>
              <CardTitle>Total de ventas de hoy</CardTitle>
              <CardDescription>
                Total de ventas: ${totalSalesToday}
              </CardDescription>
            </CardHeader>
          </Card>
          */
        }

          {/* Gráficos */}
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <Card>
              <CardHeader>
                <CardTitle>Resumen de ventas</CardTitle>
                <CardDescription>Ingresos totales durante el período seleccionado</CardDescription>
              </CardHeader>
              <CardContent>
                <Select
                  onValueChange={(value) => setTimeFilter(value)}
                  defaultValue={timeFilter}
                >
                  <SelectTrigger className="w-[180px]">
                    <SelectValue placeholder="Selecciona el tiempo para filtrar" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="7d">Esta semana </SelectItem>
                    <SelectItem value="month">Este mes</SelectItem>
                  </SelectContent>
                </Select>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={filterDataSales}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dataKey="total" stroke="#8884d8" />
                  </LineChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Top Productos</CardTitle>
                <CardDescription>Cantidad de productos vendidos</CardDescription>
              </CardHeader>
              <CardContent>
                {
                  /**
                  <Select
                  onValueChange={(value) => setProductFilter(value)}
                  defaultValue={productFilter}
                >
                  <SelectTrigger className="w-[180px]">
                    <SelectValue placeholder="Product Filter" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="top">Top 10 Productos</SelectItem>
                    <SelectItem value="worst">Peores 10 Productos</SelectItem>
                  </SelectContent>
                </Select>
                  */
                }
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={dataDetails}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="qty" fill="#82ca9d" />
                  </BarChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
            {
              /**
              {<img src={`data:image/png;base64, ${image}`} />}
              **/
            }
          </div>
        </>}
    </div>
  );
}
