import { _delete, get, patch, post, put } from "./request";

export async function getAllDetails(domain,token,){
    const data = await get(domain,token,'/api/details')
    return data
}

export async function getOneDetail(domain,token,id){
    const data = await get(domain,token,`/api/details/${id}`)
    return data
}

export async function postDetail(domain,token,body){
    const data = await post(domain,token,'/api/details',body)
    return data
}

export async function putDetail(domain,token,body,id){
    const data = await put(domain,token,`/api/details/${id}`,body)
    return data
}

export async function patchDetail(domain,token,body,id){
    const data = await patch(domain,token,`/api/details/${id}`,body)
    return data
}

export async function deleteDetail(domain,token,id){
    const data = await _delete(domain,token,`/api/details/${id}`)
    return data
}