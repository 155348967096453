import React from 'react'

import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { AppProvider } from './context/AppContext'
import { AuthProvider } from './context/AuthContext'

import Register from './pages/Register'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import PublicLayout from './layout/PublicLayout'
import PrivateLayout from './layout/PrivateLayout'
import Products from './pages/Products'
import PrivateRoute from './utils/PrivateRoute'
import Company from './pages/Company'
import Warehouses from './pages/Warehouses'
import NewProduct from './pages/NewProduct'
import Stock from './pages/Stock'
import PosList from './pages/PosList'
import Pos from './pages/Pos'
import Employee from './pages/Employee'
import Purchases from './pages/Purchases'
import ClosePos from './pages/ClosePos'
import Sales from './pages/Sales'
import CashRegister from './pages/CashRegister'


function App() {
  return (
    <Router>
      <AppProvider>
        <AuthProvider>
          <Switch>
            {/* Rutas privadas */}
            <PrivateRoute render={() => (
              <PrivateLayout>
                <Dashboard />
              </PrivateLayout>
            )} path="/dashboard" exact />

            <PrivateRoute render={() => (
              <PrivateLayout>
                <Products />
              </PrivateLayout>
            )} path="/products" exact />

            <PrivateRoute render={() => (
              <PrivateLayout>
                <NewProduct />
              </PrivateLayout>
            )} path="/new_product" exact />

            <PrivateRoute render={() => (
              <PrivateLayout>
                <Company />
              </PrivateLayout>
            )} path="/company" exact />

            <PrivateRoute render={() => (
              <PrivateLayout>
                <CashRegister />
              </PrivateLayout>
            )} path="/cash_register" exact />

            <PrivateRoute render={() => (
              <PrivateLayout>
                <Sales />
              </PrivateLayout>
            )} path="/sales" exact />

            <PrivateRoute render={() => (
              <PrivateLayout>
                <Warehouses />
              </PrivateLayout>
            )} path="/warehouses" exact />

            <PrivateRoute render={() => (
              <PrivateLayout>
                <Employee />
              </PrivateLayout>
            )} path="/employee" exact />

            <PrivateRoute render={() => (
              <PrivateLayout>
                <Stock />
              </PrivateLayout>
            )} path="/stock" exact />

            <PrivateRoute render={() => (
              <PrivateLayout>
                <PosList />
              </PrivateLayout>
            )} path="/pos/list" exact />

            <PrivateRoute render={() => (
              <PrivateLayout>
                <ClosePos />
              </PrivateLayout>
            )} path="/close_pos" exact />

            <PrivateRoute render={() => (
              <PrivateLayout>
                <Pos />
              </PrivateLayout>
            )} path="/pos" exact />

            <PrivateRoute render={() => (
              <PrivateLayout>
                <Purchases />
              </PrivateLayout>
            )} path="/purchases" exact />

            {/* Rutas publicas */}
            <Route render={() => (
              <PublicLayout>
                <Login />
              </PublicLayout>
            )} path="/login" />
            <Route render={() => (
              <PublicLayout>
                <Register />
              </PublicLayout>
            )} path="/register" exact />
            <Route render={() => (
              <PublicLayout>
                <Login />
              </PublicLayout>
            )} path="/" exact />
          </Switch>
        </AuthProvider>
      </AppProvider>
    </Router>
  )
}

export default App