import React from 'react';
import moment from 'moment-timezone';

function SalesForm({ sale,handleClose }) {
  const formatDate = (dateString) => moment.tz(dateString, "America/Santiago").format('YYYY-MM-DD HH:mm:ss');
  const paymentMethods = JSON.parse(sale.payment_method);

  return (
    <div className="container">
      <form>
        <div className="form-row">
          <div className="form-group">
            <label>Folio</label>
            <input type="text" className="form-control" value={sale.folio} disabled />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Fecha</label>
            <input type="text" className="form-control" value={formatDate(sale.date)} disabled />
          </div>

          <div className="form-group">
            <label>Precio Neto</label>
            <input type="text" className="form-control" value={sale.net_price} disabled />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Impuesto</label>
            <input type="text" className="form-control" value={sale.tax} disabled />
          </div>

          <div className="form-group">
            <label>Precio Bruto</label>
            <input type="text" className="form-control" value={sale.gross_price} disabled />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Método de Pago</label>
            <input type="text" className="form-control" value={paymentMethods.map(pm => `${pm.method}: ${pm.amount}`).join(', ')} disabled />
          </div>

          <div className="form-group">
            <label>Documento</label>
            <input type="text" className="form-control" value={sale.document} disabled />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Vendedor</label>
            <input type="text" className="form-control" value={sale.seller} disabled />
          </div>
        </div>
      </form>
      <button onClick={handleClose} className='btn mt-3'>Cerrar</button>
    </div>
  );
}

export default SalesForm;
